










































































import { Component, Vue } from "vue-property-decorator";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import _axios from "@/plugins/axios";
import agent from "@/helpers/agent";
import Role from "@/models/role";
import Notifier from "@/helpers/notifier";

@Component
export default class OrganizationsView extends Vue {
  private roles: Role[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 7,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: Role) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Claims",
      required: true,
      label: "Claims Description",
      align: "left",
      field: (row: Role) => row.claimDescription,
      sortable: true,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  truncate(string: string, length: number) {
    if (string.length > length) return string.substring(0, length) + "...";
    else return string;
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Roles.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.roles = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "UpdateRoles",
      query: { id },
    });
  }
  deleteRole(name: string) {
    agent.Roles.delete(name).then(() => {
      const index = this.roles.findIndex((i) => i.name == name);
      this.roles.splice(index, 1);
      Notifier.showInfo(`Successfully deleted`);
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "role.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
}
